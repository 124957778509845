<template>
<div class="text-nowrap text-right">
          <feather-icon
            icon="EyeIcon"
            v-b-tooltip.hover.top="'View Details!'"
            size="16"
            class="mx-1"
            @click="
              $router.push({
                name: modulefield+'-view',
                params: { id: id },
              })
            "
          />
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: modulefield+'-edit',
                params: { id: id },
              }"
            >
              <feather-icon
                :id="`electricity-row-${id}-edit-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1"
                @click="
                  $router.push({
                    name: modulefield+'-edit',
                    params: { id: id },
                  })
                "
              />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item v-on:click="checkdeletefun(id)">
              <feather-icon
                :id="`electricity-row-${id}-delete-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1"
              />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput, VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    amount
} from '@validations'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
export default {
    props: ['modulefield', 'id','checkdeletefun','printview'],
    components: {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput, VBTooltip,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
    },
    directives: {
        Ripple,
    "b-tooltip": VBTooltip,
    },
    setup(props, {
        emit
    }) {
        const {
            refFormObserver,
            getValidationState
        } = formValidation();
        return {
            refFormObserver,
            getValidationState,
        };
    },
    data() {
        return {
            required,
            amount,
        }
    },
    mounted() {
      
    },
    methods: {
    
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
