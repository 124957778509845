var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-nowrap text-right"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('View Details!'),expression:"'View Details!'",modifiers:{"hover":true,"top":true}}],staticClass:"mx-1",attrs:{"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
                name: _vm.modulefield+'-view',
                params: { id: _vm.id },
              })}}}),_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"p-0","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":{
                name: _vm.modulefield+'-edit',
                params: { id: _vm.id },
              }}},[_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("electricity-row-" + _vm.id + "-edit-icon"),"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
                    name: _vm.modulefield+'-edit',
                    params: { id: _vm.id },
                  })}}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.checkdeletefun(_vm.id)}}},[_c('feather-icon',{staticClass:"mx-1",attrs:{"id":("electricity-row-" + _vm.id + "-delete-icon"),"icon":"TrashIcon","size":"16"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }